import React, { useEffect, useContext, useRef } from "react"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"

import Layout from "../layout"
import SEO from "../seo"
import EmailCaptureModal from "../email-capture-modal"

import { IntlContext } from "../../intl"

export const query = graphql`
	query($id: String!) {
		prismicLandingPage(id: { eq: $id }) {
      uid
      url
      lang
			data {
				title {
					text
				}
        background_color
        body {
          __typename
          ... on PrismicLandingPageBodyFullHero {
            slice_type
            primary {
              nav_bar
              color
              body_centered
              body_title {
                text
              }
              body_subtitle {
                text
              }
              body_text {
                html
              }
              footer
              footer_reversed
              footer_priority
              footer_title {
                text
              }
              footer_subtitle {
                text
              }
              footer_text {
                html
              }
              footer_image {
                alt
                fluid {
                  src
                }
              }
            }
          }
          ... on PrismicLandingPageBodyPostcardTemplate {
            slice_type
            primary {
              front {
                fluid {
                  src
                }
              }
              back {
                fluid {
                  src
                }
              }
              name {
                text
              }
              description {
                text
              }
            }
          }
          ... on PrismicLandingPageBodySimpleHero {
            slice_type
            primary {
              color
              size
              headline
            }
          }
          ... on PrismicLandingPageBodySimpleText {
            slice_type
            primary {
              centered
              shadow
              background_color
              text_color
              size
              content {
                html
              }
            }
          }
          ... on PrismicLandingPageBodySubtitledText {
            slice_type
            primary {
              centered
              shadow
              background_color
              text_color
            }
            items {
              size
              subtitle {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicLandingPageBodyBetaHero {
            slice_type
          }
        }
			}
      alternate_languages {
        lang
        url
        document {
          __typename
          ... on PrismicLandingPage {
            data {
              title {
                text
              }
            }
          }
        }
      }
		}
	}
`

const LandingPage = ({ data }) => {

  const modalTriggerRef = useRef()
  const modalTriggerRef2 = useRef()
  const intl = useIntl()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicLandingPage.lang);
    setAlts(data.prismicLandingPage.alternate_languages || [])
  }, [changeLocale, setAlts, data])
  
  return (
	<Layout container={false}>
    <SEO 
      lang={data.prismicLandingPage.lang} 
      title={data.prismicLandingPage.data.title.text} 
      meta={[{
        "http-equiv": "content-language", 
        content: data.prismicLandingPage.lang
      }]} 
      links={data.prismicLandingPage.alternate_languages.concat([data.prismicLandingPage]).map(alt => ({
        rel: "alternate", 
        hreflang: alt.lang, 
        href: `https://www.customerradius.com${alt.url}`
      })).concat([{
        rel: "alternate", 
        hreflang: "x-default", 
        href: data.prismicLandingPage.lang === 'en-us'
          ? `https://www.customerradius.com${data.prismicLandingPage.url}`
          : `https://www.customerradius.com${data.prismicLandingPage.alternate_languages.find(a => a.lang === 'en-us').url}`
      }])}
    />
    <div className={`${data.prismicLandingPage.data.background_color}`}>
      {data.prismicLandingPage.data.body.map((slice, i) => {
        switch (slice.slice_type) {
          case 'full_hero':
            return (
              <div className={`hero is-fullheight${slice.primary.nav_bar?'-with-navbar':''} ${slice.primary.color}`} key={i}>
                <div className="hero-body">
                  <div className={`container ${slice.primary.body_centered?'has-text-centered':''}`}>
                    <h1 className="title is-1">{slice.primary.body_title.text}</h1>
                    <h2 className="subtitle is-2">{slice.primary.body_subtitle.text}</h2>
                    <div className="content" dangerouslySetInnerHTML={{ __html: slice.primary.body_text.html }}></div>
                  </div>
                </div>
                {slice.primary.footer && 
                  <div className="hero-footer">
                    <div className="container">
                      <div className={`columns is-vcentered ${slice.primary.footer_reversed?'is-flex-reverse-touch is-flex-reverse-desktop':''}`}>
                        <div className={`column ${slice.primary.footer_priority==='text'
                          ? 'is-three-fifths-desktop'
                          : slice.primary.footer_priority==='image'
                            ? 'is-two-fifths-desktop'
                            : ''}`}>
                          <div className="section">
                            <div className="title is-2">{slice.primary.footer_title.text}</div>
                            <div className="subtitle is-3">{slice.primary.footer_subtitle.text}</div>
                            <div className="content is-medium" dangerouslySetInnerHTML={{ __html: slice.primary.footer_text.html }}></div>
                          </div>
                        </div>
                        <div className={`column py-5 ${slice.primary.footer_priority==='text'
                          ? 'is-two-fifths-desktop'
                          : slice.primary.footer_priority==='image'
                            ? 'is-three-fifths-desktop'
                            : ''}`}>
                          {slice.primary.footer_image.fluid && <figure className="image">
                            <img src={slice.primary.footer_image.fluid.src} alt={slice.primary.footer_image.alt} />
                          </figure>}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            )

          case 'simple_hero':
            return (
              <div className={`hero ${slice.primary.color} ${slice.primary.size}`} key={i}>
                <div className="hero-body">
                  <div className="container has-text-centered">
                    <h2 className={`title ${slice.primary.size === 'is-medium'
                      ? 'is-size-2-desktop is-size-3-touch'
                      : slice.primary.size === 'is-large'
                        ? 'is-size-1-desktop is-size-2-touch'
                        : ''}`}>
                      {slice.primary.headline}
                    </h2>
                  </div>
                </div>
              </div>
            )

          case 'simple_text':
            return (
              <div className="section" key={i}>
                <div className="container">
                  <div className={`column ${slice.primary.centered ? 'is-three-fifths is-offset-one-fifth' : ''}`}>
                    <div className={`box ${slice.primary.shadow?'':'is-shadowless'} ${slice.primary.background_color||''} ${slice.primary.text_color||''}`}>
                      <div className={`content ${slice.primary.size}`} 
                        dangerouslySetInnerHTML={{ __html: slice.primary.content.html }}></div>
                    </div>
                  </div>
                </div>
              </div>
            )
          
          case 'subtitled_text':
            return (
              <div className="section" key={i}>
                <div className="container">
                  <div className={`column ${slice.primary.centered ? 'is-three-fifths is-offset-one-fifth' : ''}`}>
                    <div className={`box ${slice.primary.shadow?'':'is-shadowless'} ${slice.primary.background_color||''} ${slice.primary.text_color||''}`}>
                      {slice.items.map((item, j) => {
                        return (
                          <div className={`content ${item.size}`} key={j}>
                            <h4 className={`${slice.primary.text_color||''}`}>{item.subtitle.text}</h4>
                            <div dangerouslySetInnerHTML={{ __html: item.text.html }}></div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )
          
          case 'beta_hero':
            return (
              <div className={`hero is-warning`} key={i}>
                <div className="hero-body">
                  <div className="container has-text-centered">
                    <button className={`button is-dark is-outlined is-large`} ref={modalTriggerRef2}>
                      {intl.formatMessage({ id: "email_capture.request_early_access" })}
                    </button>
                    <EmailCaptureModal triggerRef={modalTriggerRef2} intl={intl} sourceDetail={data.prismicLandingPage.url} />
                  </div>
                </div>
              </div>
            )
          
          case 'postcard_template':
            return (
              <section className="section">
                <div className="box has-text-centered has-background-light">
                  <h4 className="title"><em>{slice.primary.name.text}</em> Template</h4>
                  <p className="block subtitle">{slice.primary.description.text}</p>
                  <div className="columns">
                    <div className="column">
                      <figure className="image">
                        <img src={slice.primary.front.fluid.src} alt={slice.primary.name.text} />
                      </figure>
                    </div>
                    <div className="column">
                      <figure className="image">
                        <img src={slice.primary.back.fluid.src} alt={slice.primary.name.text} />
                      </figure>
                    </div>
                  </div>
                </div>
              </section>
            )
        
          default:
            return null
        }
      })}
    </div>
    {/* Beta Hero */} 
    <div className={`hero is-warning`}>
      <div className="hero-body">
        <div className="container has-text-centered">
          <button className={`button is-dark is-outlined is-large`} ref={modalTriggerRef}>
            {intl.formatMessage({ id: "email_capture.request_early_access" })}
          </button>
          <EmailCaptureModal triggerRef={modalTriggerRef} intl={intl} sourceDetail={data.prismicLandingPage.url} />
        </div>
      </div>
    </div>
	</Layout>
)}

export default LandingPage
